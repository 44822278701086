<template>
  <v-app>
    <default-bar v-if="!isLogin" />

    <default-drawer v-if="!isLogin && name != 'Antpool'" />

    <default-view />

    <default-footer v-if="!isLogin" />

    <!-- <default-settings /> -->
  </v-app>
</template>

<script>
  import { get, sync } from 'vuex-pathify'

  export default {
    name: 'DefaultLayout',
    computed: {
      isLogin() {
        return this.$route.name === 'Login'
      },

      ...sync('app', ['drawer', 'mini']),
      name: get('route/name'),
    },

    components: {
      DefaultBar: () => import('./AppBar'),
      DefaultDrawer: () => import('./Drawer'),
      DefaultFooter: () => import('./Footer'),
      DefaultSettings: () => import('./Settings'),
      DefaultView: () => import('./View'),
    },
  }
</script>
